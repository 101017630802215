import React from 'react';
import { Link, graphql } from 'gatsby';
import { List, Card } from 'antd';
import classNames from 'classnames';

import { extractNodes } from '../lib/utils';
import SEO from '../layout/seo';
import { Page, Section } from '../layout/page';
import Headline from '../layout/headline';
import styles from './places.module.less';
import PageBreadcrumb from '../layout/page-breadcrumb';
import { Edges, Person } from '@types';

type Props = {
  data: {
    people: Edges<Person>;
  };
};

export const pageQuery = graphql`
  query People {
    people: allSanityPerson(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
          slug {
            current
          }
        }
      }
    }
  }
`;

const PeoplePage = ({ data }: Props) => {
  const people = extractNodes(data.people);
  const routes = [
    {
      path: `/`,
      breadcrumbName: `Home`,
      icon: 'home',
    },
    {
      path: `/people/`,
      breadcrumbName: `People`,
      icon: 'location',
    },
  ];

  return (
    <Page id="people-page" color="black" className={classNames(styles.places)}>
      <SEO title={`People - Chefs, Bartenders, Restaurateurs, & More`} />
      <Section padding={`32px 0 16px 0`}>
        <PageBreadcrumb routes={routes} color="dark" />
        <Headline
          background="black"
          lead="Meet"
          emphasis="People"
          subtitle="Chefs, bartenders, restaurateurs, and more."
        />
      </Section>
      <Section padding={`0 0 32px 0`}>
        <List
          size="large"
          grid={{
            gutter: 24,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          dataSource={people}
          renderItem={(person: Person) => {
            return (
              <List.Item className={(styles.cards, 'black')} key={person.id}>
                <Link to={`/person/${person.slug.current}/`}>
                  <Card
                  // cover={
                  //   image ? (
                  //     <Img
                  //       fluid={image.asset.fluid}
                  //       durationFadeIn={1000}
                  //       backgroundColor="black"
                  //     />
                  //   ) : null
                  // }
                  >
                    <div className={classNames(styles.details)}>
                      <h2>{person.name}</h2>
                    </div>
                  </Card>
                </Link>
              </List.Item>
            );
          }}
        />
      </Section>
    </Page>
  );
};

export default PeoplePage;
